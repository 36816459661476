import { emitter } from "@marvelapp/react-ab-test";

export const ENABLED = "Enabled";
export const DISABLED = "Disabled";
export const VARIANTA = "VariantA";
export const VARIANTB = "VariantB";

export type ExperimentVariants =
  | { enabled: string; disabled: string }
  | { variantA: string; variantB: string; disabled: string };

export interface Experiment {
  name: string;
  variants: ExperimentVariants;
  defaultVariant: string;
  weight: number[];
}

export function createExperiment(
  name: string,
  defaultVariant: string = ENABLED,
  weight: number[] = [50, 50]
): Experiment {
  const variants =
    weight.length === 3
      ? { variantA: VARIANTA, variantB: VARIANTB, disabled: DISABLED }
      : { enabled: ENABLED, disabled: DISABLED };

  return {
    name,
    variants,
    defaultVariant,
    weight,
  };
}

export const experiments: Record<string, Experiment> = {
  alternativeOnboardingEmailTitle: createExperiment("Alternative Onboarding Email Title"),
  productComparisonAlternativeCta: createExperiment("Product Comparison Alternative CTA"),
  allowCustomCheckoutSession: createExperiment("Allow Custom Checkout Session"),
  phoneNumberInPreQuotes: createExperiment("Phone Number in Pre-Quotes"),
  askForPhoneNumberBeforePetParentPage: createExperiment(
    "Ask for Phone Number before PetParent page",
    VARIANTA,
    [50, 50, 0]
  ),
  barkibuCard: createExperiment("Barkibu Card on post quotes", DISABLED, [25, 25, 50]),
  baiLinkOnPrevention: createExperiment("Bai Link On Prevention", ENABLED, [100, 0]),
};

export function setupExperiments(experimentsList = experiments): void {
  for (const experimentKey in experimentsList) {
    const experiment: Experiment = experimentsList[experimentKey];
    emitter.defineVariants(experiment.name, Object.values(experiment.variants), experiment.weight);
    emitter.calculateActiveVariant(experiment.name);
  }
}

export const isExperimentDefaultVariant = (experiment: Experiment): boolean =>
  emitter.getActiveVariant(experiment.name) === experiment.defaultVariant;

export const getExperimentVariant = (experiment: Experiment | string): string => {
  const experimentName = typeof experiment === "string" ? experiment : experiment.name;

  return emitter.getActiveVariant(experimentName);
};

export const getExperimentsList = (): Record<string, string> => {
  const experimentsList: Record<string, string> = {};

  for (const experimentKey in experiments) {
    const experiment: Experiment = experiments[experimentKey];

    experimentsList[experiment.name] = emitter.getActiveVariant(experiment.name) as string;
  }

  return {
    ...experimentsList,
  };
};

export const addExperiment = (
  experimentKey: string,
  { name, weight }: { name: string; weight: [number, number] }
): void => {
  const defaultVariant = ENABLED;
  const variants = { enabled: ENABLED, disabled: DISABLED };
  const newExperimentsList: Record<string, Experiment> = {
    ...experiments,
    [experimentKey]: { name, defaultVariant, variants, weight },
  };

  setupExperiments(newExperimentsList);
};
